<template>
  <div class="components-content">
    <div
      class="placed-the-top-dynamic"
      @click="
        $router.push({
          name: 'CreatorDynamicDetails',
          params: {
            id: topData.id,
            pid: 28
          }
        })
      "
    >
      <div class="placed-the-top-dynamic-cover">
        <img :src="topData.head_pic" alt="" />
      </div>
      <div class="placed-the-top-dynamic-info">
        <div class="placed-the-top-dynamic-title">
          {{ topData.title }}
        </div>
        <div
          v-if="topData.content"
          class="placed-the-top-dynamic-intro"
          v-html="topData.content.replace(/<[^>]+>/g, '')"
        ></div>
        <div class="placed-the-top-dynamic-time-box frcb">
          <div class="placed-the-top-dynamic-time">
            {{ topData.create_time }}
          </div>
          <div class="dynamic-arrow-icon">
            <img src="@/assets/images/dynamic_arrow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="dynamic-list-wrapper">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="dynamic-list-item frcb"
          v-for="(item, index) in list"
          :key="index"
          @click="
            $router.push({
              name: 'CreatorDynamicDetails',
              params: {
                id: item.id,
                pid: 28
              }
            })
          "
        >
          <div class="dynamic-list-item-time fcc">
            <div class="dynamic-list-item-time-day">
              {{ item.create_time | formatDate("DD") }}
            </div>
            <div class="dynamic-list-item-time-YM">
              {{ item.create_time | formatDate("YYYY-MM") }}
            </div>
          </div>
          <div class="dynamic-list-item-info">
            <div class="dynamic-list-item-info-title">
              {{ item.title }}
            </div>
            <div
            v-if="item.content"

              class="dynamic-list-item-info-intro"
              v-html="item.content.replace(/<[^>]+>/g, '')"
            ></div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { fetchSchoolBrandProject } from "@/api/school.js";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      topData: {}
    };
  },
  methods: {
    async onLoad() {
      const { result } = await fetchSchoolBrandProject({
        cate_id: 28,
        pageSize: 999999,
        pageNo: 1
      });
      this.list.push(...result.data);
      this.topData = this.list[0];
      this.loading = false;
      if (this.list.length >= result.totalCount) {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.placed-the-top-dynamic {
  background: #197b74;
  padding-bottom: 4vw;
  .placed-the-top-dynamic-cover {
    width: 100%;
    height: 50vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .placed-the-top-dynamic-info {
    padding: 0 3vw;
    .placed-the-top-dynamic-title {
      margin-top: 3.74vw;
      font-family: PingFang-SC-Bold;
      font-size: 4.27vw;
      line-height: 6.4vw;
      color: #ffffff;
    }
    .placed-the-top-dynamic-intro {
      margin-top: 5.07vw;
      font-family: PingFang-SC-Medium;
      font-size: 3.47vw;
      line-height: 5.6vw;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /*超出3行部分显示省略号，去掉该属性 显示全部*/
      -webkit-box-orient: vertical;
      color: #ffffff;
    }
    .placed-the-top-dynamic-time-box {
      margin-top: 4.14vw;
      font-family: PingFang-SC-Regular;
      font-size: 3.2vw;
      color: #ffffff;
    }
    .dynamic-arrow-icon {
      width: 8vw;
      height: 8vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.dynamic-list-wrapper {
  margin-top: 8vw;
  .dynamic-list-item {
    height: 36vw;
    border-bottom: 1px solid #eeeeee;
    .dynamic-list-item-time {
      box-sizing: border-box;
      width: 23vw;
      height: 23vw;
      background-color: #f0faf9;
      .dynamic-list-item-time-day {
        font-family: PingFang-SC-Bold;
        font-size: 8vw;
        color: #007069;
      }
      .dynamic-list-item-time-YM {
        font-family: PingFang-SC-Medium;
        font-size: 4vw;
        color: #404040;
      }
    }
    .dynamic-list-item-info {
      height: 23vw;
      width: 62vw;
      .dynamic-list-item-info-title {
        margin-top: 1.94vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: PingFang-SC-Bold;
        font-size: 5vw;
        color: #404040;
      }
      .dynamic-list-item-info-intro {
        margin-top: 2.13vw;
        font-family: PingFang-SC-Medium;
        font-size: 3vw;
        color: #838385;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
