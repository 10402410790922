import { axios } from "@/utils/request";

export function fetchBenefitAbout() {
  return axios({
    url: "/config/1",
    method: "get"
  });
}

//获取品牌项目
export function fetchBrandProject(params) {
  return axios({
    url: "/writings",
    method: "get",
    params
  });
}
