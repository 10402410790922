<template>
  <div class="menu-wrapper">
    <div class="active-menu frcb" @click="showMenu">
      <div>{{ active.title }}</div>
      <div>
        <img
          class="arrow-icon"
          :class="show ? 'active' : ''"
          src="@/assets/images/down_arrow.png"
          alt=""
        />
      </div>
    </div>
    <div class="menu-list" :class="show ? 'active' : ''">
      <div
        class="menu-item"
        :class="active.title == item.title ? 'active-color' : ''"
        @click="chooseMenu(item)"
        v-for="(item, index) in menuList"
        :key="index"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: {},
      show: false
    };
  },
  mounted() {
    this.screenComponent();
  },
  watch: {
    $route() {
      this.screenComponent();
    }
  },
  methods: {
    screenComponent() {
      this.show = false;
      const component = this.$route.params.component;
      const item = this.menuList.find(item => item.component == component);
      this.active = item;
      this.$emit("changeActive", item);
    },
    showMenu() {
      this.show = !this.show;
    },
    closeMenu() {
      this.show = false;
    },
    chooseMenu(item) {
      this.active = item;
      this.show = false;
      this.$emit("changeActive", item);
    }
  }
};
</script>

<style lang="less" scoped>
.menu-wrapper {
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  z-index: 1;
  .active-menu {
    padding: 0 3vw;
    height: 13vw;
    font-size: 4vw;
    color: #404040;
    background-color: #f4f4f4;
    .arrow-icon {
      transition: all 0.3s ease-in-out;
      &.active {
        transform: rotate(-180deg);
      }
    }
  }
  .menu-list {
    width: 100%;
    background: #ffffff;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    &.active {
      max-height: 100vw;
    }
    .menu-item {
      margin: 5vw 0 10vw 0;
      padding: 0 3vw;
      font-family: PingFang-SC-Medium;
      font-size: 4vw;
      color: #404040;
      &.active-color {
        color: #007069;
      }
    }
  }
}
</style>
